@font-face {
    font-family: 'Lato-Regular';
    src: url(../fonts/Lato-Regular.ttf);
}

html,
body {
    height: -webkit-fill-available;
    margin: 0;
    padding: 0;
    font-family: 'Lato-Regular' !important;
}

#root::after {
    background-image: url(../images/bg.png);
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: top;
}

// generales
.padre {
    display: table;

    & .hijos {
        display: table-cell;
        vertical-align: middle;
    }
}

.full {
    width: 100%;
}

.center {
    text-align: center
}

.titulo-pagina {
    font-size: 35px;
    color: #EF8F04;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.subtitulo-pagina {
    font-size: 20px;
    color: #fff;
    margin-bottom: 60px;
}

.contenedor-streaming {
    width: 100%;
    text-align: center;
    // margin-bottom: 60px;

    & .estilo-streaming {

        width: 100%;
        height: 450px;
    }
}

.uppercase {
    text-transform: uppercase;
}

.mt60 {
    margin-top: 60px;
}

.mb60 {
    margin-bottom: 60px;
}

.cursor {
    cursor: pointer;
}

.padding {
    padding: 15px;
}

::-webkit-scrollbar {
    width: 0px;
}

// .btn-login-background {
//     background: transparent linear-gradient(90deg, #BA0811 0%, #E09335 100%) 0% 0% no-repeat padding-box;
//     color: #fff;
//     padding: 12px;
//     border-radius: 30px;
//     width: 150px;
// }
.btn-orange {
    background: transparent linear-gradient(90deg, #BA0811 0%, #E09335 100%) 0% 0% no-repeat padding-box;
    color: #fff !important;
    padding: 12px !important;
    border-radius: 30px !important;
    width: 115px;
}

.btn-regresar {
    font-size: 16px;
    color: #fff;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
    .titulo-pagina {
        font-size: 22px;
    }

    .subtitulo-pagina {
        font-size: 16px;
        color: #fff;
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .titulo-pagina {
        font-size: 22px;
    }

    .subtitulo-pagina {
        font-size: 16px;
        color: #fff;
    }
}